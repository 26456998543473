<template>
    <div>
        <FormApproval type="create"></FormApproval>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
import FormApproval from "@/view/components/formApproval/FormApproval";

export default {
    components: {
        FormApproval
    }
}
</script>
